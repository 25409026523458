import { Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";
// Lazy loading components to only load component when routed
import type { RouteObject } from "react-router";

// const AuthLayout = lazy(() => import("src/layouts/Auth"));
const CommonLayout = lazy(() => import("src/layouts/Common"));
const Terms = lazy(() => import("src/views/common/terms"));
const Privacy = lazy(() => import("src/views/common/privacy"));

const commonRoutes: RouteObject = {
	path: '',
	element: (
		<Suspense fallback={null}>
			<CommonLayout />
		</Suspense>
	),
	children: [
		{
			path: '/terms',
			element: (
				<Suspense fallback={null}>
					<Terms />
				</Suspense>
			)
		},
		{
			path: '/privacy',
			element: (
				<Suspense fallback={null}>
					<Privacy />
				</Suspense>
			)
		},
		{ path: '', element: <Navigate to='/client' /> },
	]
}

export default commonRoutes;
